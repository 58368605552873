<template>
  <div class="search">
    <div class="log_page_class">
      <template>
        <el-form
          :inline="true"
          :model="queryForm"
          ref="queryForm"
          class="demo-form-inline"
        >
          <el-row :gutter="24">
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="会员账号:" prop="account">
                  <el-input
                    v-model="queryForm.account"
                    placeholder="会员账号"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="会员姓名:" prop="name">
                  <el-input
                    v-model="queryForm.name"
                    placeholder="会员姓名"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="会员状态:" prop="flag">
                  <el-select v-model="queryForm.flag" placeholder="请选择">
                    <el-option label="全部状态" :value="null" />
                    <el-option label="已启用" :value="0" />
                    <el-option label="已禁用" :value="1" />
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-form-item class="buttonbox">
                  <el-button
                    type="primary"
                    @click="loadList"
                    icon="el-icon-search"
                    >查询</el-button
                  >
                  <el-button
                    type="primary"
                    @click="resetForm('queryForm')"
                    icon="el-icon-refresh-right"
                    >重置</el-button
                  >
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </div>
    <!-- <div style="text-align: initial; margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-plus" @click="addDynamic"
        >添加</el-button
      >
    </div> -->
    <el-table
      id="table"
      :data="list"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
    >
      <el-table-column
        type="index"
        label="编号"
        width="50"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="account"
        label="账号"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="headPortraits"
        label="头像"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <el-avatar icon="el-icon-user-solid" :src="scope.row.headPortraits">
            user
          </el-avatar>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="gender"
        label="性别"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.gender === 1">男</span>
          <span v-else-if="scope.row.gender === 2">女</span>
          <span v-else>未知</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="电话"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="email"
        label="邮箱"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="idno"
        label="身份证"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="flag"
        label="会员状态"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.flag === 0 ? "已启用" : "已禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="scope.row.flag === 1"
            class="enable-button"
            @click="changeFlag(scope.row, 0)"
            >启用</el-button
          >
          <el-button
            type="text"
            size="small"
            v-if="scope.row.flag === 0"
            class="disabled-button"
            @click="changeFlag(scope.row, 1)"
            >停用</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div>
      <template>
        <el-pagination
          class="sx"
          background
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </template>
    </div>
    <div>
      <el-dialog
        title="提示"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleFlag"
        width="50%"
      >
        <div :class="flag === 1 ? 'disabled-button' : 'enable-button'">
          {{ flag === 1 ? "确认停用该会员吗？" : "确认启用该会员吗？" }}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleFlag = false">取 消</el-button>
          <el-button type="primary" @click="changeFlagSubmit()"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryForm: {
        account: "",
        flag: null,
        name: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      // 数集合
      list: [],
      dialogVisibleFlag: false,
      row: null,
      flag: null
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    async loadList() {
      this.api.platformApi
        .appUserList(this.queryForm)
        .then((res) => {
          res = res.data;
          console.log(res);
          if (res.code) {
            this.list = res.data.records;
            this.total = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
        // 状态
    changeFlag(row,num){
      this.row = row;
      this.flag = num;
      this.dialogVisibleFlag=true;
    },
    // 提交状态
    async changeFlagSubmit(){
      if(this.row.id){
        console.log(this.row);
        if(this.row.flag === 0){
          // 禁用
          await this.api.platformApi.frozen({id:this.row.id}).then(res =>{
            if(res.data.data){
              this.$message({
                message: '禁用成功！',
                type: 'success'
              });
            }else{
              this.$message.error('禁用失败！');
            }
          }).catch(err =>{
            console.log(err);
          })
        }else if(this.row.flag === 1){
          await this.api.platformApi.enable({id:this.row.id}).then(res =>{
            if(res.data.data){
              this.$message({
                message: '启用成功！',
                type: 'success'
              });
            }else{
              this.$message.error('启用失败！');
            }
          }).catch(err =>{
            console.log(err);
          })
        }
        this.dialogVisibleFlag = false;
        this.loadList();
      }
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    pageChange(val) {
      this.queryForm.pageNo = val;
      this.loadList();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  components: {},
};
</script>
<style scoped>
.tabbox {
  text-align: left;
}
#table {
  /* padding: 20px 20px 0px 20px; */
  background-color: rgba(29, 144, 255, 0);
}
.el-dialog {
  text-align: left;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
}
.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.search {
  background-color: white;
  padding: 15px 20px 100px 20px;
  overflow: hidden;
}
.sx {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

/* ------------------- */

.disabled-button {
  color: #ffac1d;
}
.enable-button {
  color: #09bf88;
}
</style>
